import React from 'react';
import VehicleCard from './VehicleCard';
import { useQuery } from '@apollo/react-hooks';
import GET_INVENTORY from '../../graphql/queries/getInventory';
import {
  useLocation
} from "react-router-dom";
import coverImage from '../../img/newarrival_img_thumb.jpg';

function Inventory() {
  let query = new URLSearchParams(useLocation().search);
  const loadingSquares = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }];
  const makeFilter = query.get("make") === "all" || !query.get("make") ? null : query.get("make");
  const bodyFilter = query.get("bodystyle") === "all" || !query.get("bodystyle") ? null : query.get("bodystyle");
  const minYearFilter = query.get("yearMin") === "2010" || !query.get("yearMin") ? null : query.get("yearMin");
  const maxYearFilter = query.get("yearMax") === "2020" || !query.get("yearMax") ? null : query.get("yearMax");
  const minPriceFilter = query.get("priceMin") === "0" || !query.get("priceMin") ? null : query.get("priceMin");
  const maxPriceFilter = query.get("priceMax") === "0" || !query.get("priceMax") ? null : query.get("priceMax");
  const minMilesFilter = query.get("milesMin") === "0" || !query.get("milesMin") ? null : query.get("milesMin");
  const maxMilesFilter = query.get("milesMax") === "0" || !query.get("milesMax") ? null : query.get("milesMax");
  const { loading, data } = useQuery(GET_INVENTORY, {
    variables: {
      make: makeFilter,
      bodystyle: bodyFilter,
      minYear: minYearFilter,
      maxYear: maxYearFilter,
      minPrice: minPriceFilter,
      maxPrice: maxPriceFilter,
      minMiles: minMilesFilter,
      maxMiles: maxMilesFilter,
    }
  });
  return (
    <div className="content mt-1 mt-md-5">
      <div className="container-fluid">
        <div className="header text-center text-sm-left">
          <h1 className="header-title">Bay Area's quality pre-owned vehicles.</h1>
          <p className="header-subtitle">High-quality cars. Easy financing. Affordable pricing.</p>
        </div>
        {loading ? (
          <div className="row">
            {loadingSquares.map(square => {
              return (
                <div key={square.id} className="col-xs-12 col-md-6 col-lg-4 col-xl-3 mt-3">
                  <div style={{ height: "250px", backgroundColor: "#FAFAFA" }} className="gradient">
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
            <div className="row">
              {data.posts.length > 0 ? (
                data.posts.map(vehicle => {
                  return (
                    <VehicleCard key={vehicle.id} vehicleInfo={vehicle} coverImage={coverImage} />
                  )
                })
              ) : (
                  <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
                    <h5>No vehicles found. Try updating your filters in the left sidebar.</h5>
                  </div>
                )}
            </div>)}
      </div>
    </div>
  );
}

export default Inventory;