import React, { useEffect } from 'react';
import './scss/App.scss';
import Home from './Home';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import {
  Switch,
  Route
} from "react-router-dom";
import ReactGA from 'react-ga';
import Contact from './Contact';
import Finance from './Finance';
import Vehicle from './Vehicle';
import Thankyou from './Thankyou';

const client = new ApolloClient({
  uri: "https://sanjoseamigos.herokuapp.com/v1/graphql"
})

function App() {
  useEffect(() => {
    ReactGA.initialize('UA-153219083-1');
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/finance' component={Finance} />
        <Route exact path='/thankyou' component={Thankyou} />
        <Route exact path='/v/' component={Home} />
        <Route path='/v/:id' component={Vehicle} />
      </Switch>
    </ApolloProvider>
  );
}

export default App;
