import { gql } from 'apollo-boost';

const GET_INVENTORY = gql`
query ($make: String, $bodystyle: String, $minYear: Int, $maxYear: Int, $minPrice: Int, $maxPrice: Int, $minMiles: Int, $maxMiles: Int){
  posts(where: {_and: {vehicle_make: {_eq: $make}, vehicle_body: {_eq: $bodystyle}, vehicle_year: {_gte: $minYear, _lte: $maxYear}, vehicle_price: {_gte: $minPrice, _lte: $maxPrice}, vehicle_mileage: {_gte: $minMiles, _lte: $maxMiles}}}) {
    id
    vehicle_year
    vehicle_make
    vehicle_model
    vehicle_vin
    vehicle_body
    vehicle_drivetrain
    vehicle_engine
    vehicle_mileage
    vehicle_price
    vehicle_transmission
    vehicle_trim
    notable_feature_1
    notable_feature_2
    notable_feature_3
    notable_feature_4
    notable_feature_5
    downpayment
    vehicle_images
    }
  }
`;

export default GET_INVENTORY;