import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import GET_VEHICLE_MAKES_BODY from '../graphql/queries/getVehicleMakes';
import GET_VEHICLES_COUNT from '../graphql/queries/getVehicleMakesCount';
import { debounce } from "lodash";

const sendQueryDebounced = (val, searchParamObj, setSearchParamObj, filterType) => {
  setSearchParamObj({
    ...searchParamObj,
    [filterType]: val
  });
};

function Sidebar(props) {
  let history = useHistory();

  const [makeCollapsed, setMakeCollapsed] = useState(false);
  const [makeFilter, setMakeFilter] = useState("all");

  const [bodyTypeCollapsed, setBodyTypeCollapsed] = useState(false);
  const [bodystyleFilter, setBodystyleFilter] = useState("all");

  const [yearCollapsed, setYearCollapsed] = useState(false);
  const [yearMinFilter, setYearMinFilter] = useState(2010);
  const [yearMaxFilter, setYearMaxFilter] = useState(2020);

  const [priceCollapsed, setPriceCollapsed] = useState(false);
  const [minPriceFilter, setMinPriceFilter] = useState("");
  const [maxPriceFilter, setMaxPriceFilter] = useState("");

  const [milesCollapsed, setMilesCollapsed] = useState(false);
  const [minMilesFilter, setMinMilesFilter] = useState("");
  const [maxMilesFilter, setMaxMilesFilter] = useState("");

  const [filtersExist, setFiltersExist] = useState(false);
  const [searchParamObj, setSearchParamObj] = useState({});
  
  const delayedHistoryPush = useCallback(debounce((val, searchParamObj, setSearchParamObj, filterType) => sendQueryDebounced(val, searchParamObj, setSearchParamObj, filterType), 500), []);

  const { data: dataMakes } = useQuery(GET_VEHICLE_MAKES_BODY);
  const { data: dataVehiclesCount } = useQuery(GET_VEHICLES_COUNT);

  useEffect(() => {
    let searchString = Object.keys(searchParamObj).map(key => `${key}=${encodeURIComponent(searchParamObj[key])}`).join('&');
    history.push({
      pathname: '/',
      search: searchString
    })
  }, [searchParamObj]);

  const onResetAllFilters = () => {
    history.push({
      pathname: '/',
      search: ""
    })
    setSearchParamObj({});
    setFiltersExist(false);
    // Reset state filters to default
    setMakeFilter("all");
    setBodystyleFilter("all");
    setYearMinFilter(2010);
    setYearMaxFilter(2020);
    setMinPriceFilter("");
    setMaxPriceFilter("");
    setMinMilesFilter("");
    setMaxMilesFilter("");
  }

  const onMakeFilterSelect = (val) => {
    setSearchParamObj({
      ...searchParamObj,
      make: val
    });
    setMakeFilter(val);
    setFiltersExist(true);
  }

  const onBodyStyleFilterSelect = (val) => {
    setSearchParamObj({
      ...searchParamObj,
      bodystyle: val
    });
    setBodystyleFilter(val);
    setFiltersExist(true);
  }

  const onUpdateMinYearFilter = (val) => {
    setSearchParamObj({
      ...searchParamObj,
      yearMin: val
    });
    setYearMinFilter(val);
    setFiltersExist(true);
  }

  const onUpdateMaxYearFilter = (val) => {
    setSearchParamObj({
      ...searchParamObj,
      yearMax: val
    });
    setYearMaxFilter(val);
    setFiltersExist(true);
  }

  const onUpdateMinPriceFilter = (val) => {
    if (val >= 0 || val === "") {
      delayedHistoryPush(val, searchParamObj, setSearchParamObj, "priceMin");
      setMinPriceFilter(val);
      setFiltersExist(true);
    }
  }

  const onUpdateMaxPriceFilter = (val) => {
    if (val >= 0 || val === "") {
      delayedHistoryPush(val, searchParamObj, setSearchParamObj, "priceMax");
      setMaxPriceFilter(val);
      setFiltersExist(true);
    }
  }

  const onUpdateMinMilesFilter = (val) => {
    if (val >= 0 || val === "") {
      delayedHistoryPush(val, searchParamObj, setSearchParamObj, "milesMin");
      setMinMilesFilter(val);
      setFiltersExist(true);
    }
  }

  const onUpdateMaxMilesFilter = (val) => {
    if (val >= 0 || val === "") {
      delayedHistoryPush(val, searchParamObj, setSearchParamObj, "milesMax");
      setMaxMilesFilter(val);
      setFiltersExist(true);
    }
  }

  return (
    <nav className={props.navbarToggleState ? "sidebar toggled" : "sidebar"}>
      <div className="sidebar-content">
        <div className="sidebar-user pb-4">
          <div>
            <h3 className="text-dark font-weight-normal">Filter Inventory</h3>
          </div>
          {filtersExist ? (
            <button className="btn btn-sm btn-secondary" onClick={() => onResetAllFilters()}>RESET ALL FILTERS</button>
          ) : (
              <button disabled className="btn btn-sm btn-outline-secondary disabled">SHOWING ALL {dataVehiclesCount ? dataVehiclesCount.posts_aggregate.aggregate.count : 0} VEHICLES</button>
            )}
        </div>
        <ul className="sidebar-nav">
          <li className="sidebar-item">
            <span data-toggle="collapse" className={makeCollapsed ? "sidebar-link" : "sidebar-link collapsed"} onClick={() => setMakeCollapsed(!makeCollapsed)} aria-expanded={makeCollapsed ? "true" : "false"}>
              <span className="align-middle text-dark text-uppercase font-weight-normal">Vehicle Make</span></span>
            <div className={makeCollapsed ? "collapse show" : "collapse"} aria-expanded={makeCollapsed ? "true" : "false"}>
              <ul id="item" className="sidebar-dropdown list-unstyled">
                <li className="sidebar-item ">
                  <span className={makeFilter === "all" ? "sidebar-link font-weight-bold text-uppercase text-primary" : "sidebar-link"} onClick={() => onMakeFilterSelect("all")}>All Makes ({dataVehiclesCount ? dataVehiclesCount.posts_aggregate.aggregate.count : 0})</span>
                </li>
                {dataMakes ? (
                  dataMakes.makes_count.map(makeObj => {
                    return (
                      <li key={makeObj.vehicle_make} className="sidebar-item ">
                        <span className={makeFilter === makeObj.vehicle_make ? "sidebar-link font-weight-bold text-uppercase text-primary" : "sidebar-link"} onClick={() => onMakeFilterSelect(makeObj.vehicle_make)}>{makeObj.vehicle_make} ({makeObj.count})</span>
                      </li>
                    )
                  })
                ) : null}
              </ul>
            </div>
          </li>
          <li className="sidebar-item">
            <span data-toggle="collapse" className={bodyTypeCollapsed ? "sidebar-link" : "sidebar-link collapsed"} onClick={() => setBodyTypeCollapsed(!bodyTypeCollapsed)} aria-expanded={bodyTypeCollapsed ? "true" : "false"}>
              <span className="align-middle text-dark text-uppercase font-weight-normal">Body Type</span></span>
            <div className={bodyTypeCollapsed ? "collapse show" : "collapse"} aria-expanded={bodyTypeCollapsed ? "true" : "false"}>
              <ul id="item" className="sidebar-dropdown list-unstyled">
                <li className="sidebar-item ">
                  <span className={bodystyleFilter === "all" ? "sidebar-link font-weight-bold text-uppercase text-primary" : "sidebar-link"} onClick={() => onBodyStyleFilterSelect("all")}>All Types ({dataVehiclesCount ? dataVehiclesCount.posts_aggregate.aggregate.count : 0})</span>
                </li>
                {dataMakes ? (
                  dataMakes.bodystyle_count.map(bodystyleObj => {
                    return (
                      <li key={bodystyleObj.vehicle_body} className="sidebar-item ">
                        <span className={bodystyleFilter === bodystyleObj.vehicle_body ? "sidebar-link font-weight-bold text-uppercase text-primary" : "sidebar-link"} onClick={() => onBodyStyleFilterSelect(bodystyleObj.vehicle_body)}>{bodystyleObj.vehicle_body} ({bodystyleObj.count})</span>
                      </li>
                    )
                  })
                ) : null}
              </ul>
            </div>
          </li>
          <li className="sidebar-item">
            <span data-toggle="collapse" className={yearCollapsed ? "sidebar-link" : "sidebar-link collapsed"} onClick={() => setYearCollapsed(!yearCollapsed)} aria-expanded={yearCollapsed ? "true" : "false"}>
              <span className="align-middle text-dark text-uppercase font-weight-normal">Year</span></span>
            <div className={yearCollapsed ? "collapse show" : "collapse"} aria-expanded={yearCollapsed ? "true" : "false"}>
              <div className="d-flex m-3 justify-content-around">
                <div>
                  <p className="pl-2 mb-0 text-muted">From</p>
                  <select className="mb-3 form-control" value={yearMinFilter} onChange={e => onUpdateMinYearFilter(e.target.value)}>
                    <option value={2010}>2010</option>
                    <option value={2011}>2011</option>
                    <option value={2012}>2012</option>
                    <option value={2013}>2013</option>
                    <option value={2014}>2014</option>
                    <option value={2015}>2015</option>
                    <option value={2016}>2016</option>
                    <option value={2017}>2017</option>
                    <option value={2018}>2018</option>
                    <option value={2019}>2019</option>
                    <option value={2020}>2020</option>
                  </select>
                </div>
                <div><h5 className="pt-4">--</h5></div>
                <div className="ml-1">
                  <p className="pl-1 mb-0 text-muted">To</p>
                  <select className="mb-3 form-control" value={yearMaxFilter} onChange={e => onUpdateMaxYearFilter(e.target.value)}>
                    <option value={2010}>2010</option>
                    <option value={2011}>2011</option>
                    <option value={2012}>2012</option>
                    <option value={2013}>2013</option>
                    <option value={2014}>2014</option>
                    <option value={2015}>2015</option>
                    <option value={2016}>2016</option>
                    <option value={2017}>2017</option>
                    <option value={2018}>2018</option>
                    <option value={2019}>2019</option>
                    <option value={2020}>2020</option>
                  </select>
                </div>
              </div>
            </div>
          </li>
          <li className="sidebar-item">
            <span data-toggle="collapse" className={priceCollapsed ? "sidebar-link" : "sidebar-link collapsed"} onClick={() => setPriceCollapsed(!priceCollapsed)} aria-expanded={priceCollapsed ? "true" : "false"}>
              <span className="align-middle text-dark text-uppercase font-weight-normal">Price</span></span>
            <div className={priceCollapsed ? "collapse show" : "collapse"} aria-expanded={priceCollapsed ? "true" : "false"}>
              <div className="d-flex m-3">
                <div>
                  <p className="pl-2 mb-0 text-muted">From</p>
                  <input type="number" min="0" className="form-control" placeholder="No Min" value={minPriceFilter} onChange={e => onUpdateMinPriceFilter(e.target.value)}></input>
                </div>
                <div className="ml-1">
                  <p className="pl-1 mb-0 text-muted">To</p>
                  <input type="number" className="form-control" placeholder="No Max" value={maxPriceFilter} onChange={e => onUpdateMaxPriceFilter(e.target.value)}></input>
                </div>
              </div>
            </div>
          </li>
          <li className="sidebar-item">
            <span data-toggle="collapse" className={milesCollapsed ? "sidebar-link" : "sidebar-link collapsed"} onClick={() => setMilesCollapsed(!milesCollapsed)} aria-expanded={milesCollapsed ? "true" : "false"}>
              <span className="align-middle text-dark text-uppercase font-weight-normal">Miles</span></span>
            <div className={milesCollapsed ? "collapse show" : "collapse"} aria-expanded={milesCollapsed ? "true" : "false"}>
              <div className="d-flex m-3">
                <div>
                  <p className="pl-2 mb-0 text-muted">From</p>
                  <input type="number" className="form-control" placeholder="No Min" value={minMilesFilter} onChange={e => onUpdateMinMilesFilter(e.target.value)}></input>
                </div>
                <div className="ml-1">
                  <p className="pl-1 mb-0 text-muted">To</p>
                  <input type="number" className="form-control" placeholder="No Max" value={maxMilesFilter} onChange={e => onUpdateMaxMilesFilter(e.target.value)}></input>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Sidebar;