import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import messengerLogo from './img/messenger.png';
import whatsappLogo from './img/whatsapp.png';
import callLogo from './img/call.png';
import pinLogo from './img/pin.png';
import {
  MobileView
} from "react-device-detect";

ReactDOM.render(
  <BrowserRouter>
    <App />
    <MobileView>
      <div className="actionButtons pt-1">
        <div className="row">
          <div className="col-3 text-center p-0">
            <a href="tel:408-477-1470" className="actionButtonItem" targe="_blank" rel="noopener noreferrer"><img src={callLogo} alt="Call" style={{ width: "30px" }}></img></a>
            <p className="text-center font-weight-bold text-sm">Call / Text</p>
          </div>
          <div className="col-3 text-center p-0 border-left">
            <a href="https://wa.me/14084771470" className="actionButtonItem" targe="_blank" rel="noopener noreferrer"><img src={whatsappLogo} alt="WhatsApp" style={{ width: "30px" }}></img></a>
            <p className="text-center font-weight-bold text-sm">WhatsApp</p>
          </div>
          <div className="col-3 text-center p-0 border-left">
            <a href="https://m.me/thrivemotors" className="actionButtonItem" targe="_blank" rel="noopener noreferrer"><img src={messengerLogo} alt="Messenger" style={{ width: "30px" }}></img></a>
            <p className="text-center font-weight-bold text-sm">Messenger</p>
          </div>
          <div className="col-3 text-center p-0 border-left">
            <a href="https://goo.gl/maps/BDhwWAbwvBtLtui57" target="_blank" rel="noopener noreferrer" className="actionButtonItem" targe="_blank"><img src={pinLogo} alt="Directions" style={{ width: "30px" }}></img></a>
            <p className="text-center font-weight-bold text-sm">Directions</p>
          </div>
        </div>
      </div>
    </MobileView>
  </BrowserRouter>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
