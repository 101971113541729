import { gql } from 'apollo-boost';

const GET_VEHICLES_COUNT = gql`
{
  posts_aggregate {
    aggregate {
      count
    }
  }
}
`;

export default GET_VEHICLES_COUNT;