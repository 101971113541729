import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

function VehicleCard(props) {
  const vehicle = props.vehicleInfo;
  const vehicleDetailsLink = "/v/" + vehicle.id;
  return (
    <div className="col-xs-12 col-md-6 col-lg-4 col-xl-3">
      <Link to={vehicleDetailsLink} className="card">
        <img width="100%" src={vehicle.vehicle_images ? JSON.parse(vehicle.vehicle_images)[0] : props.coverImage} alt="Vehicle" className="card-img-top" style={{ height: "220px", objectFit: "cover" }}></img>
        <div className="card-header pt-0">
          <h3 className="mb-0 card-title tab-title text-dark">{vehicle.vehicle_year} {vehicle.vehicle_make} {vehicle.vehicle_model}</h3>
          <h5 className="text-sm pt-1">{vehicle.vehicle_trim}, {vehicle.vehicle_drivetrain} | <NumberFormat value={vehicle.vehicle_mileage} displayType={'text'} thousandSeparator={true} /> miles</h5>
          <h5 className="text-sm mt-3"><NumberFormat value={vehicle.vehicle_price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h5>
        </div>
      </Link>
    </div>
  );
}

export default withRouter(VehicleCard);