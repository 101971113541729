import { gql } from 'apollo-boost';

const GET_VEHICLE_MAKES_BODY = gql`
query {
  makes_count(order_by: {vehicle_make: asc}) {
    count
    vehicle_make
  }
  bodystyle_count {
    count
    vehicle_body
  }
}
`;

export default GET_VEHICLE_MAKES_BODY;