import React from 'react';
import '../scss/App.scss';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

function Thankyou() {
  return (
    <div>
      <div className="wrapper">
        <div className="main">
          <Header />
          <div className="content">
            <div className="container-fluid">
              <div className="header text-center">
                <h1 className="header-title">Thank You</h1>
                <p className="header-subtitle">We have received your credit application.</p>
              </div>
              <div className="row">
                <div className="col-md-10 offset-md-1 col-12 text-center">
                  <h4>Our team will be in touch as soon as possible.<br></br>In the meantime, click on the button below to continue shopping.</h4>
                  <Link to="/"><button className="btn btn-lg btn-primary">See Inventory</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
