import React, { useState } from 'react';
import '../scss/App.scss';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Inventory from './components/Inventory';

function Home() {
  const [navbarToggleState, setNavbarToggleState] = useState(false);
  return (
    <div>
      <div className="wrapper">
        <Sidebar navbarToggleState={navbarToggleState} />
        <div className="main pb-6">
          <Header navbarToggleState={navbarToggleState} setNavbarToggleState={setNavbarToggleState} />
          <Inventory />
        </div>
      </div>
    </div>
  );
}

export default Home;
