import { gql } from 'apollo-boost';

const GET_VEHICLE_BY_ID = gql`
query ($id: uuid){
  posts(where: {id: {_eq: $id}}) {
    id
    vehicle_year
    vehicle_make
    vehicle_model
    vehicle_vin
    vehicle_body
    vehicle_drivetrain
    vehicle_engine
    vehicle_mileage
    vehicle_price
    vehicle_transmission
    vehicle_trim
    notable_feature_1
    notable_feature_2
    notable_feature_3
    notable_feature_4
    notable_feature_5
    downpayment
    vehicle_images
    }
  }
`;

export default GET_VEHICLE_BY_ID;