import React from 'react';
import '../scss/App.scss';
import Header from '../components/Header';
import thriveMap from '../img/thriveMap.jpg';

function Contact() {
  return (
    <div>
      <div className="wrapper">
        <div className="main">
          <Header />
          <div className="content">
            <div className="container-fluid">
              <div className="header text-center">
                <h1 className="header-title">Contact Us</h1>
                <p className="header-subtitle">We would love to hear from you.</p>
              </div>
              <div className="row">
                <div className="col-md-10 offset-md-1 col-12">
                  <div className="my-3 row text-center">
                    <div className="col-lg-4 col-12">
                      <i className="ion-ios-call" style={{ fontSize: "4rem" }} />
                      <a href="tel:+14084771470"><h3>(408) 477-1470</h3></a>
                      <h5>Call / Text</h5>
                    </div>
                    <div className="col-lg-4 col-12">
                      <i className="ion-ios-mail" style={{ fontSize: "4rem" }} />
                      <a href="mailto:hello@thrivemotors.com"><h3>hello@thrivemotors.com</h3></a>
                      <h5>Email</h5>
                    </div>
                    <div className="col-lg-4 col-12">
                      <i className="ion-ios-pin" style={{ fontSize: "4rem" }} />
                      <a href="https://goo.gl/maps/BDhwWAbwvBtLtui57" target="_blank" rel="noopener noreferrer"><h3 className="mb-0 card-title text-lg">395 S Winchester Blvd <br></br>San Jose CA 95128</h3></a>
                      <h5>Address</h5>
                    </div>
                  </div>
                  <img width="100%" src={thriveMap} alt="395 S Winchester Blvd" className="card-img-top"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
