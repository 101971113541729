import React, { useEffect, useState } from 'react';
import '../scss/App.scss';
import Header from '../components/Header';
import MaskedInput from 'react-text-mask';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';

function Finance() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [employerName, setEmployerName] = useState("");
  const [hireDate, setHireDate] = useState("");
  const [ssnItin, setSsnItin] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [downPaymentAmount, setDownPaymentAmount] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [buttonTitle, setBtnTitle] = useState("Submit Application");
  let history = useHistory();
  useEffect(() => {
    // Scroll to top
    window.scrollTo(0, 0)
  }, []);

  const checkIfFormFilled = () => {
    let leadFilled = firstName.length > 0 && lastName.length > 0 && email.length > 0 && phoneNumber.length > 0;
    let leadDetailsFilled = dateOfBirth.length > 0 && street.length > 0 && zipCode.length > 0 && employerName.length > 0 && hireDate.length > 0 && ssnItin.length > 0 && monthlyIncome > 0;
    if (!!leadFilled && !!leadDetailsFilled) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  }

  const onFirstNameChange = (val) => {
    setFirstName(val);
    checkIfFormFilled()
  }

  const onLastNameChange = (val) => {
    setLastName(val);
    checkIfFormFilled()
  }

  const onStreetChange = (val) => {
    setStreet(val);
    checkIfFormFilled()
  }

  const onEmployerNameChange = (val) => {
    setEmployerName(val);
    checkIfFormFilled()
  }

  const onMonthlyIncomeChange = (val) => {
    setMonthlyIncome(val);
    checkIfFormFilled()
  }

  const onEmailChange = (val) => {
    setEmail(val);
    checkIfFormFilled()
  }

  const onDateOfBirthChange = (val) => {
    setDateOfBirth(val);
    checkIfFormFilled()
  }

  const onZipCodeChange = (val) => {
    setZipCode(val);
    checkIfFormFilled()
  }

  const onPhoneNumberChange = (val) => {
    setPhoneNumber(val);
    checkIfFormFilled()
  }

  const onSsnItinChange = (val) => {
    setSsnItin(val);
    checkIfFormFilled()
  }

  const onDownPaymentChange = (val) => {
    setDownPaymentAmount(val);
    checkIfFormFilled()
  }

  const onHireDateChange = (val) => {
    setHireDate(val);
    checkIfFormFilled()
  }

  const submitForm = (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    setBtnTitle("Submitting...");
    let dateOfBirthTemp = new Date(dateOfBirth);
    let dateOfBirthFormatted = dateOfBirthTemp.toISOString();
    let hireDateTemp = new Date(hireDate);
    let hireDateFormatted = hireDateTemp.toISOString();
    let zipCodeFormatted = parseInt(zipCode);
    let monthlyIncomeFormatted = parseInt(monthlyIncome);
    let downPaymentAmountFormatted = parseInt(downPaymentAmount);
    let ssnItinFormatted = parseInt(ssnItin.replace(/\D/g, ''));
    var appParams = {
      "FirstName": firstName,
      "LastName": lastName,
      "DateOfBirth": dateOfBirthFormatted,
      "StreetAddress": street,
      "ZIPCode": zipCodeFormatted,
      "PhoneNumber": phoneNumber,
      "Employer": employerName,
      "HireDate": hireDateFormatted,
      "MonthlyIncomeBeforeTaxes": monthlyIncomeFormatted,
      "UTMCampaign": "Thrive Motors",
      "DownPayment": downPaymentAmountFormatted,
      "Email": email,
      "SSN": ssnItinFormatted
    }
    var appParamsQuery = JSON.stringify(appParams);

    fetch(`https://amigos-mailer.herokuapp.com/mail`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: appParamsQuery
    }).then(() => {
      history.push('/thankyou/');
    });
  }


  return (
    <div>
      <div className="wrapper">
        <div className="main pb-6">
          <Header />
          <div className="content">
            <div className="container-fluid">
              <div className="header text-center">
                <h1 className="header-title">Get Pre-Approved</h1>
                <p className="header-subtitle">Fill out the credit application below.</p>
              </div>
              <div className="row">
                <div className="col-md-8 offset-md-2 col-12">
                  <div className="card modal-xl">
                    <div className="card-header text-center">
                      <h1><i className="ion-ios-lock display-3 text-success"></i></h1>
                      <h4 className="font-weight-normal my-3">Secure Credit Application</h4>
                    </div>
                    <form className="card-body">
                      <div className="form-row">
                        <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
                          <div className="form-group">
                            <label className="">First Name</label>
                            <input name="firstName" placeholder="First Name" type="text" value={firstName} onChange={e => onFirstNameChange(e.target.value)} className="form-control form-control-lg"></input>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                          <div className="form-group">
                            <label className="">Last Name</label>
                            <input name="lastName" placeholder="Last Name" type="text" value={lastName} onChange={e => onLastNameChange(e.target.value)} className="form-control form-control-lg"></input>
                          </div>
                        </div>
                      </div>
                      <div className="form-row my-3">
                        <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
                          <div className="form-group">
                            <label className="">Date of Birth</label>
                            <input name="dateOfBirth" value={dateOfBirth} onChange={e => onDateOfBirthChange(e.target.value)} type="date" min="1900-01-01" max="2020-01-01" className="form-control form-control-lg" />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                          <div className="form-group">
                            <label className="">Phone Number</label>
                            <MaskedInput name="phoneNumber" value={phoneNumber} onChange={e => onPhoneNumberChange(e.target.value)} className={"form-control form-control-lg"} mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
                          </div>
                        </div>
                      </div>
                      <div className="form-row my-3">
                        <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
                          <div className="form-group">
                            <label className="">Street Address</label>
                            <input name="street" placeholder="Street" type="text" value={street} onChange={e => onStreetChange(e.target.value)} className="form-control form-control-lg"></input>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                          <div className="form-group">
                            <label className="">ZIP Code</label>
                            <MaskedInput name="zipCode" value={zipCode} onChange={e => onZipCodeChange(e.target.value)} className={"form-control form-control-lg"} mask={[/\d/, /\d/, /\d/, /\d/, /\d/]} />
                          </div>
                        </div>
                      </div>
                      <div className="form-row my-3">
                        <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
                          <div className="form-group">
                            <label className="">Employer Name</label>
                            <input name="employer" placeholder="Employer" type="text" value={employerName} onChange={e => onEmployerNameChange(e.target.value)} className="form-control form-control-lg"></input>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                          <div className="form-group">
                            <label className="">Hire Date</label>
                            <input name="hireDate" value={hireDate} type="date" onChange={e => onHireDateChange(e.target.value)} className="form-control form-control-lg" min="1900-01-01" />
                          </div>
                        </div>
                      </div>
                      <div className="form-row my-3">
                        <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
                          <div className="form-group">
                            <label className="">Monthly Income Before Taxes</label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>$</InputGroupText>
                              </InputGroupAddon>
                              <Input name="monthlyIncome" placeholder="Gross Monthly Income" value={monthlyIncome} onChange={e => onMonthlyIncomeChange(e.target.value)} type="number" className="form-control form-control-lg" />
                            </InputGroup>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                          <div className="form-group">
                            <label className="">SSN / ITIN</label>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText><i className="ion-ios-lock text-success"></i></InputGroupText>
                              </InputGroupAddon>
                              <MaskedInput name="ssnItin" value={ssnItin} onChange={e => onSsnItinChange(e.target.value)} className={"form-control form-control-lg"} mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} />
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                      <div className="form-row my-3">
                        <div className="col-12 col-sm-6 col-xl-4 offset-xl-2">
                          <div className="form-group">
                            <label className="">Email</label>
                            <input name="email" placeholder="Email" type="text" value={email} onChange={e => onEmailChange(e.target.value)} className="form-control form-control-lg"></input>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-xl-4">
                          <div className="form-group">
                            <label className="">Down Payment</label>
                            <select name="downpaymentAmount" value={downPaymentAmount} onChange={e => onDownPaymentChange(e.target.value)} className="form-control form-control-lg" required>
                              <option value="">--Please choose an option--</option>
                              <option value={1}>0 - 1,500</option>
                              <option value={1501}>1,500 - 2,500</option>
                              <option value={2501}>2,500 - 3,500</option>
                              <option value={3501}>3,500 - 5,000</option>
                              <option value={5001}>5,000 - 10,000</option>
                              <option value={10001}>10,000+</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-8 offset-md-2">
                          <p className="small text-muted">By clicking on Submit Application, you consent to receive autodialed marketing calls and text messages from or on behalf of Thrive Motors at the telephone numbers(s) provided above, including your wireless number, if applicable. You understand that consent is not a condition of purchase. You also agree that by submitting this application, you authorize and give Thrive Motors, as well as any potential financing source this dealership presents this application to, your consent to obtain your credit report from any credit reporting agency used to complete an investigation of your credit.</p>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                          <button className="btn btn-success btn-lg btn-block" onClick={e => submitForm(e)} disabled={btnDisabled}>{buttonTitle}</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finance;
