import React from 'react';
import '../scss/App.scss';
import { Link, withRouter } from 'react-router-dom';
import thriveLogoRed from '../img/thriveLogoRed.png';

function Header(props) {
  return (
    <nav className="navbar-light navbar navbar-expand flex-column flex-md-row">
      {!!props.setNavbarToggleState ? (
        <ul className="navbar-nav">
          <li className="ml-lg-2-5 nav-item mt-2">
            <span className="sidebar-toggle" onClick={() => props.setNavbarToggleState(!props.navbarToggleState)}>
              <i className="ion-ios-options align-self-center text-dark" style={{fontSize: "1.8rem"}} />
            </span>
          </li>
          <li className="ml-lg-1 nav-item">
            <Link to="/" className="navbar-brand ml-4 mr-0 mr-md-2 border-left pl-4">
              <img src={thriveLogoRed} alt="Thrive Motors" style={{ width: "218px" }}></img>
            </Link>
          </li>
        </ul>
      ) : (
          <Link to="/" className="navbar-brand mt-3 mr-0 mr-md-2 mt-md-0">
            <img src={thriveLogoRed} alt="Thrive Motors" style={{ width: "218px" }}></img>
          </Link>
        )}
      <div className="collapse navbar-collapse" aria-expanded="false">
        <ul className="ml-auto navbar-nav">
          <li className="ml-lg-1 nav-item">
            <Link to="/" className="nav-icon position-relative nav-link cursor-pointer">
              Inventory
            </Link>
          </li>
          <li className="ml-lg-1 nav-item">
            <Link to="/contact" className="nav-icon position-relative nav-link cursor-pointer">
              Contact
            </Link>
          </li>
          <li className="ml-lg-1 nav-item">
            <Link to="/finance" className="nav-icon position-relative nav-link cursor-pointer">
              <button className="btn btn-dark">Get Pre-Approved</button>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default withRouter(Header);
