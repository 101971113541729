import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import '../scss/App.scss';
import Header from '../components/Header';
import GET_VEHICLE_BY_ID from '../graphql/queries/getVehicleById';
import NumberFormat from 'react-number-format';
import ImageGallery from 'react-image-gallery';
import PaymentCalculator from './components/paymentCalculator';
import thriveMap from '../img/thriveMap.jpg';
import coverImage from '../img/newarrival_img.jpg'
import coverImageThumb from '../img/newarrival_img_thumb.jpg'
import { Link } from 'react-router-dom';

const coverImages = [
  {
    original: coverImage,
    thumbnail: coverImageThumb,
  },
];

function Vehicle({ match }) {
  const vehicleId = match.params.id;
  let vehicle;
  let images;
  let carfaxLink;
  const { loading, data } = useQuery(GET_VEHICLE_BY_ID, {
    variables: { id: vehicleId },
  });
  if (data) {
    vehicle = data.posts[0];
    carfaxLink = `http://www.carfax.com/VehicleHistory/p/Report.cfx?partner=DVW_1&vin=${vehicle.vehicle_vin}`;
    if (vehicle.vehicle_images) {
      let imagesTemp = Object.values(JSON.parse(vehicle.vehicle_images));
      images = [];
      for (var i = 0; i < imagesTemp.length; i++) {
        if (imagesTemp[i].startsWith("http")) {
          images.push({ original: imagesTemp[i], thumbnail: imagesTemp[i] });
        }
      }
    }
  }
  return (
    <div>
      <div className="wrapper">
        <div className="main">
          <Header />
          <div className="content pb-6">
            {loading ? (
              <div className="container-fluid">
                <div className="header text-center">
                  <p className="header-subtitle">Loading...</p>
                </div>
              </div>
            ) : (
                <div className="container-fluid">
                  <div className="header text-center">
                    <h1 className="header-title">{vehicle.vehicle_year} {vehicle.vehicle_make} {vehicle.vehicle_model}</h1>
                    <p className="header-subtitle">{vehicle.vehicle_trim}, {vehicle.vehicle_drivetrain} | <NumberFormat value={vehicle.vehicle_mileage} displayType={'text'} thousandSeparator={true} /> miles</p>
                    <Link to="/finance" className="btn btn-large btn-danger p-2 pl-4 pr-4 text-lg">Get Pre-Approved Now</Link>
                    <a href={carfaxLink} target="_blank" rel="noopener noreferrer" className="btn btn-large btn-light p-2 pl-4 pr-4 text-lg ml-0 ml-sm-3 mt-2 mt-sm-0">Free CARFAX Report</a>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-12">
                      <div className="card modal-xl">
                        <ImageGallery items={images ? images : coverImages} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 offset-md-2 col-12">
                      <div className="modal-xl">
                        <div className="row my-5">
                          <div className="col-12">
                            <h1 className="display-5 font-weight-bold">Vehicle Info</h1>
                            <div className="my-5">
                              {vehicle.notable_feature_1 ? <h5 className="d-flex align-items-center"><i className="ion-ios-checkbox mr-3" style={{ fontSize: "2rem" }} /> {vehicle.notable_feature_1}</h5> : null}
                              {vehicle.notable_feature_2 ? <h5 className="d-flex align-items-center"><i className="ion-ios-checkbox mr-3" style={{ fontSize: "2rem" }} /> {vehicle.notable_feature_2}</h5> : null}
                              {vehicle.notable_feature_3 ? <h5 className="d-flex align-items-center"><i className="ion-ios-checkbox mr-3" style={{ fontSize: "2rem" }} /> {vehicle.notable_feature_3}</h5> : null}
                              {vehicle.notable_feature_4 ? <h5 className="d-flex align-items-center"><i className="ion-ios-checkbox mr-3" style={{ fontSize: "2rem" }} /> {vehicle.notable_feature_4}</h5> : null}
                              {vehicle.notable_feature_5 ? <h5 className="d-flex align-items-center"><i className="ion-ios-checkbox mr-3" style={{ fontSize: "2rem" }} /> {vehicle.notable_feature_5}</h5> : null}
                            </div>
                          </div>
                          <div className="col-12 col-lg-4">
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_year}</h4>
                            <p className="text-muted">Year</p>
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_make} {vehicle.vehicle_model}</h4>
                            <p className="text-muted">Make and Model</p>
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_body}</h4>
                            <p className="text-muted">Body Type</p>
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_trim}</h4>
                            <p className="text-muted">Trim</p>
                          </div>
                          <div className="col-12 col-lg-4">
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_engine}</h4>
                            <p className="text-muted">Engine</p>
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_transmission}</h4>
                            <p className="text-muted">Transmission</p>
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_drivetrain}</h4>
                            <p className="text-muted">Drivetrain</p>
                          </div>
                          <div className="col-12 col-lg-4">
                            <h4 className="font-weight-normal mb-0"><NumberFormat value={vehicle.vehicle_price} displayType={'text'} thousandSeparator={true} prefix="$" /></h4>
                            <p className="text-muted">Price</p>
                            <h4 className="font-weight-normal mb-0"><NumberFormat value={vehicle.vehicle_mileage} displayType={'text'} thousandSeparator={true} /> miles</h4>
                            <p className="text-muted">Mileage</p>
                            <h4 className="font-weight-normal mb-0">{vehicle.vehicle_vin}</h4>
                            <p className="text-muted">VIN Number</p>
                          </div>
                        </div>
                        <div className="row my-5">
                          <div className="col-12">
                            <h1 className="display-5 mb-4 font-weight-bold">Create Your Deal</h1>
                          </div>
                        </div>
                        <div className="card">
                          <PaymentCalculator vehicleInfo={vehicle} />
                        </div>
                        <div className="row my-5">
                          <div className="col-12">
                            <h1 className="display-5 mb-4 font-weight-bold">Get in Touch</h1>
                          </div>
                        </div>
                        <div className="row pb-3">
                          <div className="col-12 text-center">
                            <p>Want to check if the vehicle is <strong>still available</strong>? Want to <strong>get pre-approved</strong>? Have any other questions or feedback?<br></br>Please feel free to call, text, email or visit us and we will be more than happy to help!</p>
                            <div className="my-3 row text-center">
                              <div className="col-lg-4 col-12">
                                <i className="ion-ios-call" style={{ fontSize: "4rem" }} />
                                <a href="tel:+14084771470"><h3>(408) 477-1470</h3></a>
                                <h5>Call / Text</h5>
                              </div>
                              <div className="col-lg-4 col-12">
                                <i className="ion-ios-mail" style={{ fontSize: "4rem" }} />
                                <a href="mailto:hello@thrivemotors.com"><h3>hello@thrivemotors.com</h3></a>
                                <h5>Email</h5>
                              </div>
                              <div className="col-lg-4 col-12">
                                <i className="ion-ios-pin" style={{ fontSize: "4rem" }} />
                                <a href="https://goo.gl/maps/BDhwWAbwvBtLtui57" target="_blank" rel="noopener noreferrer"><h3 className="mb-0 card-title text-lg">395 S Winchester Blvd <br></br>San Jose CA 95128</h3></a>
                                <h5>Address</h5>
                              </div>
                            </div>
                            <img width="100%" src={thriveMap} alt="395 S Winchester Blvd" className="card-img-top"></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div >
    </div >
  );
}

export default Vehicle;
