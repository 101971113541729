import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

function PaymentCalculator(props) {
  const vehicle = props.vehicleInfo;
  const [downpaymentVal, setDownpaymentVal] = useState(Math.round(vehicle.vehicle_price * 0.20));
  const [termVal, setTermVal] = useState(60);
  const [creditVal, setCreditVal] = useState(3);
  const [aprValLow, setAprValLow] = useState(0.02999);
  const [aprValHigh, setAprValHigh] = useState(0.07999);

  const handleTermClick = (term) => {
    setTermVal(term);
  }

  const updateDownPaymentValue = (value) => {
    var totalAmount = calculateTotalAmount();
    if ((value >= 0 && value <= totalAmount) || value === "") {
      setDownpaymentVal(value);
    }
  }

  const handleCreditClick = (credit) => {
    setCreditVal(credit);
    if (credit === 0) {
      setAprValLow(0.15999);
      setAprValHigh(0.19999);
    } else if (credit === 1) {
      setAprValLow(0.18999);
      setAprValHigh(0.22999);
    } else if (credit === 2) {
      setAprValLow(0.08999);
      setAprValHigh(0.14999);
    } else if (credit === 3) {
      setAprValLow(0.02999);
      setAprValHigh(0.07999);
    } else {
      setAprValLow(0.18999);
      setAprValHigh(0.22999);
    }
  }

  const getEstimatedAprLow = () => {
    var aprTemp = aprValLow * 100;
    var aprLow = aprTemp.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return aprLow;
  }

  const getEstimatedAprHigh = () => {
    var aprTemp = aprValHigh * 100;
    var aprHigh = aprTemp.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
    return aprHigh;
  }

  const calculateAllFees = () => {
    var salesTaxTemp = (vehicle.vehicle_price + 65) * 0.0925;
    var salesTax = Math.round(salesTaxTemp);
    var vehicleFees = 138;
    var totalFees = vehicleFees + salesTax;
    return totalFees;
  }

  const calculateTotalAmount = () => {
    var totalFees = calculateAllFees();
    var docFee = 65;
    var totalAmount = vehicle.vehicle_price + totalFees + docFee;

    return totalAmount;
  }

  const calculateAmountFinanced = () => {
    var totalAmount = calculateTotalAmount();
    var amountFinanced = totalAmount - downpaymentVal;

    return amountFinanced;
  }

  const calculateMonthlyPaymentLow = () => {
    var amountFinanced = calculateAmountFinanced();
    var R = aprValLow / 12;
    var term = termVal;
    var daystofirst = 30;
    let totalnew = amountFinanced + (R * ((daystofirst - 30) / 30)) * amountFinanced;
    var monthlyPaymentTemp = (totalnew * R) / (1 - Math.pow(1 + R, -term));
    var monthlyPayment = Math.round(monthlyPaymentTemp);

    return monthlyPayment;
  }

  const calculateMonthlyPaymentHigh = () => {
    var amountFinanced = calculateAmountFinanced();
    var R = aprValHigh / 12;
    var term = termVal;
    var daystofirst = 30;
    let totalnew = amountFinanced + (R * ((daystofirst - 30) / 30)) * amountFinanced;
    var monthlyPaymentTemp = (totalnew * R) / (1 - Math.pow(1 + R, -term));
    var monthlyPayment = Math.round(monthlyPaymentTemp);

    return monthlyPayment;
  }

  return (
    <div className="row">
      <div className="col-12 col-sm-7 text-center">
        <h5 className="payment-section-title">How is your credit history?</h5>
        <div className="payment-calculator-btn-group">
          <div className={creditVal === 0 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleCreditClick(0)}>
            <p className="payment-calculator-btn-title">No Credit</p>
            <p className="payment-calculator-btn-subtext">No Score</p>
          </div>
          <div className={creditVal === 1 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleCreditClick(1)}>
            <p className="payment-calculator-btn-title">Rebuilding</p>
            <p className="payment-calculator-btn-subtext">600 or less</p>
          </div>
          <div className={creditVal === 2 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleCreditClick(2)}>
            <p className="payment-calculator-btn-title">Average</p>
            <p className="payment-calculator-btn-subtext">600 - 700</p>
          </div>
          <div className={creditVal === 3 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleCreditClick(3)}>
            <p className="payment-calculator-btn-title">Excellent</p>
            <p className="payment-calculator-btn-subtext">700+</p>
          </div>
        </div>
        <p className="estimated-apr">Estimated APR Range {getEstimatedAprLow()}% - {getEstimatedAprHigh()}%</p>
        <h5 className="payment-section-title">Select preferred loan term</h5>
        <div className="payment-calculator-btn-group">
          <div className={termVal === 36 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleTermClick(36)}>
            <p className="payment-calculator-btn-title">36</p>
            <p className="payment-calculator-btn-subtext">Months</p>
          </div>
          <div className={termVal === 48 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleTermClick(48)}>
            <p className="payment-calculator-btn-title">48</p>
            <p className="payment-calculator-btn-subtext">Months</p>
          </div>
          <div className={termVal === 54 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleTermClick(54)}>
            <p className="payment-calculator-btn-title">54</p>
            <p className="payment-calculator-btn-subtext">Months</p>
          </div>
          <div className={termVal === 60 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleTermClick(60)}>
            <p className="payment-calculator-btn-title">60</p>
            <p className="payment-calculator-btn-subtext">Months</p>
          </div>
          <div className={termVal === 72 ? 'payment-calculator-btn-active' : 'payment-calculator-btn'} onClick={() => handleTermClick(72)}>
            <p className="payment-calculator-btn-title">72</p>
            <p className="payment-calculator-btn-subtext">Months</p>
          </div>
        </div>
        <h5 className="payment-section-title pb-0 mb-0">Enter a down payment amount</h5>
        <p className="estimated-apr pt-0 cursor-pointer" onClick={() => setDownpaymentVal(Math.round(vehicle.vehicle_price * 0.20))}>Default set to 20% of the selling price</p>
        <div className="mt-2 row">
          <div className="col-8 offset-2">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input type="number" className="form-control form-control-lg" placeholder="Down payment" value={downpaymentVal} onChange={e => updateDownPaymentValue(e.target.value)}></input>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-5">
        <div className="payment-calculations">
          <div className="row">
            <div className="col-sm-12 payment-calculator-line">
              <div className="payment-calculator-hint text-capitalized"><p>Selling Price</p></div>
              <div className="payment-calculator-value text-capitalized"><h4>$<NumberFormat value={vehicle.vehicle_price} displayType={'text'} thousandSeparator={true} /></h4></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 payment-calculator-line">
              <div className="payment-calculator-hint text-capitalized"><p>Documentation</p></div>
              <div className="payment-calculator-value text-capitalized"><h5>$65</h5></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 payment-calculator-line">
              <div className="payment-calculator-hint text-capitalized"><p>Taxes and Fees</p></div>
              <div className="payment-calculator-value text-capitalized"><h5>$<NumberFormat value={calculateAllFees()} displayType={'text'} thousandSeparator={true} /></h5></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 payment-calculator-line bt1 pt-2">
              <div className="payment-calculator-hint text-capitalized"><p>TOTAL (Out the Door)</p></div>
              <div className="payment-calculator-value text-capitalized"><h5><strong>$<NumberFormat value={calculateTotalAmount()} displayType={'text'} thousandSeparator={true} /></strong></h5></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 payment-calculator-line">
              <div className="payment-calculator-hint text-capitalized"><p>Down Payment</p></div>
              <div className="payment-calculator-value text-capitalized"><h5>-$<NumberFormat value={downpaymentVal} displayType={'text'} thousandSeparator={true} /></h5></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 payment-calculator-line">
              <div className="payment-calculator-hint text-capitalized"><p>Finance Amount</p></div>
              <div className="payment-calculator-value text-capitalized"><h5>$<NumberFormat value={calculateAmountFinanced()} displayType={'text'} thousandSeparator={true} /></h5></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 payment-calculator-line bt1 pt-2 calign">
              <div className="payment-calculator-hint text-capitalized"><p>Est. Monthly Payment Range</p></div>
              <div className="payment-calculator-value text-capitalized estimated-monthly"><h4>${calculateMonthlyPaymentLow()} - ${calculateMonthlyPaymentHigh()}</h4></div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p className="taxes-disclaimer">We make every effort to provide accurate information, but please verify before purchasing. Vehicle subject to prior sale. Applicable transfer fees are due in advance of vehicle delivery and are separate from sales transactions. Taxes and fees are estimates only based on a registration address in 95128. The payment is only an estimate and not a guarantee. Loan terms may vary and are based on approved credit.</p>
              <div className="vehicle-heading-buttons">
                <Link to="/finance" className="btn payment-cta-btn"><button className="btn btn-dark btn-lg">Get Pre-Approved Now</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PaymentCalculator);